import { globalHistory } from '@reach/router';
import { Observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { REACT_APP_ORIGIN, useGetReactAppOrigin } from '../../env';
import { useGetAppAuthToken } from '../../utils/app.utils';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import AppLogo from '../AppLogo/AppLogo';
import BaseButton from '../BaseButton/BaseButton';
import CTAButton from '../CTAButton/CTAButton';
import PageSection from '../PageSection/PageSection';
import { usePageContext } from '../PageTemplate/PageTemplate';
import './NavBar.scss';

type NavBarProps = {}

const NavBar: React.FC<NavBarProps> = props => {

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        document.documentElement.classList.remove('mobile-menu-open');
      }
    })
  })

  function handleMobileMenuToggle() {
    // e.preventDefault();
    document.documentElement.classList.toggle('mobile-menu-open');

  }

  const page = usePageContext();
  const authToken = useGetAppAuthToken();
  const appURL = useGetReactAppOrigin();

  return <Observer children={() => (
    <PageSection as="nav" id="NavBar" className="NavBar">
      <div className="NavBarSlotStart">
        <AnimatedLink className="NavBarPrimaryLogoLink" to="/" title="Trackster Home">
          <AppLogo />
        </AnimatedLink>
      </div>
      <div className="NavBarSlotEnd" key={`${appURL}_${authToken}`}>
        {authToken
          ? <CTAButton className='GoToAppBtn' path={`${appURL}/app/timer`} title="Go to App" colorMode="translucent">Go To App</CTAButton>
          : <>
            <CTAButton className='LoginInBtn' path={`${appURL ?? REACT_APP_ORIGIN}/auth/login`} title="Log in" colorMode="transparent">Log in</CTAButton>
            <CTAButton className='SignUpBtn' path={`${appURL ?? REACT_APP_ORIGIN}/auth/register`} title="Sign up" colorMode="translucent">Sign up</CTAButton>
          </>
        }

        {/* <button className="NavBarHamburger" onClick={handleMobileMenuToggle}>
          <svg width="24" height="24" viewBox="0 0 24 24"><use xlinkHref="#icon-hamburger" /></svg>
        </button> */}

        <BaseButton className="NavBarHamburger" onClick={handleMobileMenuToggle}>
          <svg width="24" height="24" viewBox="0 0 24 24"><use xlinkHref="#icon-hamburger" /></svg>
        </BaseButton>

      </div>
      <div className="NavBarSlotCenter">
        <div className="NavBarSiteNav">
          <AnimatedLink to="/" title="Home">Home</AnimatedLink>
          {/* <Line /> */}
          <AnimatedLink to="/features" title="Features">Features</AnimatedLink>
          {/* <Line /> */}
          <AnimatedLink to="/pricing" title="Pricing"><span>Pricing</span></AnimatedLink>
          <AnimatedLink to="/contact" title="Contact"><span>Contact</span></AnimatedLink>
          {/* <AnimatedLink to="/blog" title="Blog">Blog</AnimatedLink> */}
        </div>
      </div>
    </PageSection>
  )} />
}

export default NavBar;