import { action, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { isBrowser } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { ObservableRef, useObservableRef } from '../../hooks/useObservableRef.hook';
import joinClassName from '../../utils/className.utils';
import { makeDisposerController } from '../../utils/disposer.utils';
import { useStore } from '../../utils/mobx.utils';
import { observeVisibility } from '../../utils/visibilityObserver.util';
import './PageSection.scss';

type PageSectionProps = {
  id: string,
  as?: 'section' | 'header' | 'footer' | 'article' | 'nav',
  className?: string,
  observeVisibility?: boolean,
}

const makePageSectionController = (ref?: ObservableRef, props?: PageSectionProps) => {
  return observable({
    id: props?.id,
    ref,
    visible: isBrowser ? ref && observeVisibility ? false : true : true,
  })
}

export type PageSectionContext = ReturnType<typeof makePageSectionController>;
const PageSectionContext = React.createContext(makePageSectionController());
export const usePageSectionContext = () => useContext(PageSectionContext);

const PageSection: React.FC<PageSectionProps> = props => {
  const ref = useObservableRef();
  const s = useStore(() => ({
    context: makePageSectionController(ref, props),
  }))
  useOnMount(() => {
    const d = makeDisposerController();
    if (isBrowser && props.observeVisibility) d.add(
      observeVisibility(ref, {
        id: props.id,
        onBecomeVisible: action(() => s.context.visible = true),
        onBecomeInvisible: action(() => s.context.visible = false),
      })
    );
    return d.disposer
  })
  return <Observer children={() => <PageSectionContext.Provider value={s.context}>
    {
      React.createElement(
        props.as ?? 'section',
        {
          id: props.id,
          className: joinClassName('PageSection', props.className),
          ref,
          children: <div className="PageSectionInner">
            {props.children}
          </div>
        }
      )
    }
  </PageSectionContext.Provider>} />
}

export default PageSection;