import { graphql, useStaticQuery } from 'gatsby';
import { SiteMetaDataQuery } from '../types/generated';

const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          keywords
        }
      }
    }`,
  ) as SiteMetaDataQuery;
  return site!.siteMetadata! as {
    title: string,
    description: string,
    author: string,
    siteUrl: string,
    keywords: string[],
  };
};

export default useSiteMetadata;