import { Observer } from 'mobx-react-lite';
import React from 'react';
import './AppLogo.scss';

type AppLogoProps = {}

const AppLogo: React.FC<AppLogoProps> = props => {
  return <Observer children={() => (
    <div className="AppLogo">
      <span>trackster</span>
    </div>
  )} />
}

export default AppLogo;