import { CSSPlugin, gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';
import { SplitText } from 'gsap/SplitText';
import { Observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useAppContext } from '../../controllers/app.controller';
import { isBrowser, isDevelopment, isProduction } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { readFromStorage } from '../../hooks/useSyncObservableStateToStorage.hook';
import '../../style/index.scss';
import { PageComponentProps } from '../../types/gatsby.types';
import joinClassName from '../../utils/className.utils';
import { reportError } from '../../utils/loggers.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import tick, { runAfter } from '../../utils/waiters.utils';
import NavBar from '../NavBar/NavBar';
import SEO, { SEOProps } from '../SEO';
import StandardFooter from '../StandardFooter/StandardFooter';
import { ThemeSetterProps } from '../ThemeSetter/ThemeSetter';
import './PageTemplate.scss';


if (isBrowser) {
  gsap.registerPlugin(
    CSSPlugin,
    DrawSVGPlugin,
    SplitText,
    MorphSVGPlugin
  );
}

type PageTemplateProps = {
  className?: string,
} & PageComponentProps & SEOProps & ThemeSetterProps

const makePageContextController = (props?: PageTemplateProps) => {
  return props;
}
const PageContext = React.createContext(makePageContextController());
export const usePageContext = () => useContext(PageContext) as PageTemplateProps;

declare global {
  interface Window {
    CONTEXT?: PageTemplateProps,
  }
}

const PageTemplate: React.FC<PageTemplateProps> = props => {

  const p = useProps(props);
  const s = useStore(() => ({
    context: makePageContextController(p),
  }))
  const { UI } = useAppContext();

  useOnMount(() => {
    (async () => {
      if (isBrowser) {
        document.body.setAttribute('data-current-page', p.className ?? '');
        const GDPR_PREFERENCES = readFromStorage('GDPR_PREFERENCES');
        if (isDevelopment) {
          window.CONTEXT = s.context;
        }
        try {
          if (GDPR_PREFERENCES?.enableGoogleAnalytics && process.env.GTAG_ID && isProduction && !UI.isFirstLoad && !!window.ga) {
            const pathname = s.context?.location.pathname;
            await tick(500); // wait a bit for page title to update, in case it didn't happen right away
            window.ga('set', 'page', pathname);
            window.ga.getAll()[0]?.send('pageview', pathname);
          }
          runAfter(() => window.dispatchEvent(new Event('resize')), 500) // in case scrollbar appears / disappears after page load
        } catch (e) {
          reportError('Error accessing GA');
          console.error(e);
        }
      }
    })()
  })

  return <Observer children={() => (
    <PageContext.Provider value={s.context}>
      <SEO {...props} />
      <div className={
        joinClassName(
          'PageTemplate',
          props.className,
          isBrowser ? 'browser' : 'ssr',
        )
      }>
        <NavBar />
        {/* { UI.cssFeatures.customProperties && <ThemeSetter {...props} /> } */}
        <main>
          {props.children}
        </main>
        <StandardFooter />
      </div>
    </PageContext.Provider>
  )} />

}

export default PageTemplate;