import { Observer } from 'mobx-react-lite';
import React from 'react';
import { REACT_APP_ORIGIN, useGetReactAppOrigin } from '../../env';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import AppLogo from '../AppLogo/AppLogo';
import PageSection from '../PageSection/PageSection';
import PseudoLink from '../PseudoLink/PseudoLink';
import './StandardFooter.scss';

type StandardFooterProps = {}

const StandardFooter: React.FC<StandardFooterProps> = props => {
  const appURL = useGetReactAppOrigin();
  return <Observer children={() => <>
    <PageSection id="StandardFooter" as="footer" className="StandardFooter" observeVisibility>
      <header className="StandardFooterHeader">
        <h2><AnimatedLink to="/" title="Home">
          <AppLogo />
        </AnimatedLink></h2>
      </header>
      <nav className="StandardFooterMajorLinks">
        <p>
          <AnimatedLink to="/features" title="Features"><span>Features</span></AnimatedLink>
          {/* <Line /> */}
        </p>
        <p>
          <AnimatedLink to="/pricing" title="Pricing"><span>Pricing</span></AnimatedLink>
          {/* <Line /> */}
        </p>
        <p>
          <AnimatedLink to="/contact" title="Contact"><span>Contact</span></AnimatedLink>
          {/* <Line /> */}
        </p>
        <p>
          <a key={appURL} href={`${appURL ?? REACT_APP_ORIGIN}/auth/register`}><span>Get your free trial</span></a>
          {/* <AnimatedLink to="/" title="Get your free trial"><span>Get your free trial</span></AnimatedLink> */}
          {/* <AnimatedLink to="https://app.trackster.io/" title="Get your free trial"><span>Get your free trial</span></AnimatedLink> */}
          {/* <Line /> */}
        </p>
      </nav>
      <div className="StandardFooterMetaInfo">
        <p><span>© {new Date().getFullYear()} <AnimatedLink to="/" title="Trackster Home">Trackster</AnimatedLink>.</span> <span>Designed and Built by <a href="https://axondivision.com" title="Axon Division Website">Axon Division</a>.</span> <span>All Rights Reserved.</span></p>
        {/* <address><strong>Address:</strong> <span>133 Harold's Cross Rd.,</span> <span>Dublin D6W ED77,</span> <span>Republic of Ireland</span></address>
        <p><strong>Company Reg. No.: 526061</strong></p>
        <p>Connect with us: <a href="https://www.linkedin.com/company/axondivision" target="_blank" rel="noreferrer">LinkedIn</a></p> */}
      </div>
      <footer className="StandardFooterFooter">
        <ul className="StandardFooterMinorLinks">
          <li><AnimatedLink to="/data-protection-policy" title="Data Protection Policy">Data Protection Policy</AnimatedLink></li>
          <li><PseudoLink title="Privacy Settings" onClick={() => window.MANAGE_PRIVACY_SETTINGS()}>Privacy Settings</PseudoLink></li>
          <li><AnimatedLink to="/about" title="About">About</AnimatedLink></li>
          {/* <li><a href="/sitemap.xml" title="Sitemap" target="_blank">Sitemap</a></li> */}
        </ul>
      </footer>
    </PageSection>
  </>} />
}

export default StandardFooter;