import { toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { GADataLayerCustomEntry } from '../../types/app.types';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { getRandomNumericString } from '../../utils/random.utils';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import './CTAButton.scss';

export type CTAButtonProps = {
  className?: string,
  path?: string
  to?: string
  title: string,
  target?: string,
  rel?: string,
  onClick?: () => void,
  dark?: boolean,
  outlined?: boolean,
  isLoading?: boolean
  disabled?: boolean,
  Label?: string,
  LoadingLabel?: string,
  gaEvent?: GADataLayerCustomEntry,
  doNotAnimate?: boolean,
  colorMode?: 'solid' | 'translucent' | 'white' | 'transparent',
}

const CTAButton: React.FC<CTAButtonProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    id: `CTAButton-${getRandomNumericString()}`,
    handleClick: () => {
      p.onClick?.();
      if (p.gaEvent) window.dataLayer.push(toJS(p.gaEvent));
    },
    get wrapperCommonAttr() {
      return {
        className: joinClassName(
          'CTAButtonWrapper',
          p.colorMode ?? 'solid',
        )
      }
    },
    get commonAttr() {
      return {
        id: s.id,
        className: joinClassName(
          'CTAButton',
          p.className,
          p.colorMode ?? 'solid',
          p.dark && 'dark',
          p.outlined && 'outlined',
          p.target === '_blank' && 'external',
          p.isLoading && 'loading',
          (s.disabled && !p.isLoading) && 'disabled',
        ),
        title: p.title,
        onClick: s.handleClick,
        children: <>
          <span className="CTAButtonTextLabel">
            {p.isLoading ? p.LoadingLabel ?? p.Label : p.Label}
            {p.children}
          </span>
        </>,
      }
    },
    get disabled() {
      return p.disabled || p.isLoading;
    },
    get linkOrButton() {
      return (p.to || p.path) && !s.disabled ? (
        (p.target === '_blank' || p.path) ? <a
          href={p.path ?? p.to}
          target={p.target}
          rel={p.rel ?? (p.target === '_blank' ? 'noreferrer' : '')}
          {...s.commonAttr}
        /> : <AnimatedLink
          to={p.to!}
          {...s.commonAttr}
        />
      ) : <button
        type="button"
        disabled={s.disabled}
        {...s.commonAttr}
      />
    },
  }))

  return <Observer children={() => (
    <span {...s.wrapperCommonAttr}>
      {s.linkOrButton}
    </span>
  )} />
}

export default CTAButton;